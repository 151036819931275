<template>
  <c-box w="100%" style="position: relative" bg="vc-red">
    <c-flex
      class="header"
      align="['start','start', 'space-between']"
      :flexDirection="['column', 'column', 'row']"
      justify="space-between"
      mt="4"
      mb="4"
    >
      <c-flex
        pt="10px"
        justify="['space-between','start', 'space-between']"
        align="center"
      >
        <c-heading
          pt="2"
          fontSize="2xl"
          fontWeight="700"
          mr="3"
          :mb="['2', '2', '0']"
        >
          Company Profile
        </c-heading>
      </c-flex>
      <c-flex align="center" justify="['start','end', 'space-between']">
        <search @hide-sidebar="handleHideSidebar" />

        <HeaderOptions />
      </c-flex>
    </c-flex>

    <c-flex :flexDirection="['column', 'column', 'row']">
      <c-flex h="100%" w="100%" :flex="hideSidebar ? '1' : '3'">
        <profile isCompany />
      </c-flex>
      <c-flex v-if="!hideSidebar" ml="5" :flex="hideSidebar ? '0' : '3'">
        <Sidebar />
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import Profile from '@/views/App/Profile/components/Profile.vue';
import Sidebar from '@/views/App/Explore/Sidebar.vue';
import HeaderOptions from '@/views/App/components/HeaderOptions.vue';
import Search from '@/views/App/Explore/Search.vue';

export default {
  components: {
    Profile,
    Sidebar,
    HeaderOptions,
    Search,
  },
  props: ['companyId', 'type'],
  data() {
    return {
      hideSidebar: false,
    };
  },

  methods: {
    handleHideSidebar() {},
  },
};
</script>
